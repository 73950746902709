@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/variables";

$small-devices: 600px;
$tablets: 768px;
$desktops: 1024px;
$large-screens: 1200px;

$white: #FFF;
$black: #000;
$light-gray: #f6f6f6;
$medium-gray: #E2E6E9;
$dark-gray: #434D56;
$red: #D43370;
$dark-red: #d22d3a;
$yellow: #ffae00;
$light-green: #61CE70;
$green: #14B137;
$turquoise: #08A286;
$light-aqua: #4FBCCB;
$aqua: #11ABC1;
$light-blue: #9fc8ef;
$blue: #1569AE;
$dark-blue: #154D80;
$nlx-blue: #1c2f42;

$text: #434D56;
$action-blue: $blue;
$accent: $green;
$ripl-border-radius: 1px;

//bootstrap theme colors
$primary: $aqua;
$secondary: $dark-gray;
$success: $green;
$info: $turquoise;
$warning: $yellow;
$danger: $dark-red;


@mixin fullscreen-width($top-padding: 0, $bottom-padding: 0) {
    margin: 0 auto;
    padding: $top-padding 1rem $bottom-padding 1rem;
    width: 100%;

    @media screen and (min-width: $desktops) {
        max-width: 1200px;
    }

    @media screen and (min-width: $large-screens) {
        max-width: 1600px;
    }
}

@mixin button-style {
    border-color: rgba($secondary, .25);
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: $dark-gray;
    font-weight: bold;
    font-size: 0.8rem;
    letter-spacing: -0.01em;
}

:export {
    smallDevices: $small-devices;
    tablets: $tablets;
    desktops: $desktops;
    largeScreens: $large-screens;
    lightBlue: $light-blue;
    darkBlue: $dark-blue;
    lightGray: $light-gray;
    mediumGray: $medium-gray;
    darkGray: $dark-gray;
    secondary: $secondary;
}